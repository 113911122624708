import React, {Component} from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import { Link } from "gatsby"
import WomenWork__rs from "../images/womenwork.png"
import igadget__rs from "../images/igadget2.jpg"
import Petdog__rs from "../images/petdog.png"
import Amazon_case from "../images/amazon_casestudy.jpg"
import Atg_website from "../images/atg-website-img.png"
import Perennial_img from "../images/perennialcycle/perennial-img.png"
import Perennial_seo from "../images/perennialcycle/seo-perennial.jpg"
import Betty_img from "../images/bitty-biddy/bitty-resources.jpg"
import Seed_Planet from "../images/atg/seed_planet.png"
import Dipro_img from "../images/diamonds-pro/dipro_img.png"
import Ansel_Brand from "../images/anselivy/ansel-res.png"
import ddu_resources from "../images/diamond-designs/ddu-resources.jpg"
import ATG_Seeds from "../images/atg-conv/atg-seeds.jpg"
import DR_B from "../images/drB/casestudylogodrb.png"
import JIVA_AG from "../images/jiva-ag/Jiva.png"

import apex from "../images/caseStudy/am-1.png"
import atg from "../images/caseStudy/atg-1.png"
import bab from "../images/caseStudy/bab-1.png"
import buk from "../images/caseStudy/bk-1.png"
import cad from "../images/caseStudy/cea-1.png"
import clam from "../images/caseStudy/cc-1.png"
import dakine from "../images/caseStudy/dk-1.png"
import flare from "../images/caseStudy/flare-1.png"
import goat from "../images/caseStudy/gw-1.png"
import grantastic from "../images/caseStudy/graintastic.png"
import IG from "../images/caseStudy/ig-1.png"
import jiva from "../images/caseStudy/jv-1.png"
import kiwi from "../images/caseStudy/kw-1.png"
import LP from "../images/caseStudy/lp-1.png"
import myF from "../images/caseStudy/mf-1.png"
import nirvana from "../images/caseStudy/ns-1.png"
import paradise from "../images/caseStudy/pg-1.png"
import PD from "../images/caseStudy/pd-1.png"
import PC from "../images/caseStudy/pc-1.png"
import thtr from "../images/caseStudy/th-1.png"
import mob_logo from "../images/caseStudy/mob_logo.png"
import herology from "../images/caseStudy/hero-1.png"
import rw from "../images/caseStudy/rw-1.png"
import pj from "../images/caseStudy/pj-1.png"
import ww from "../images/caseStudy/ww.png"
import sch from "../images/caseStudy/sw-1.png"
import bc from "../images/caseStudy/bc-1.png"
import dd from "../images/caseStudy/dd-1.png"
import dp from "../images/caseStudy/dipro-1.png"
import ryda from "../images/caseStudy/rd-1.png"
import pm from "../images/caseStudy/pm-1.png"
import az from "../images/caseStudy/az-1.png"
import blc from "../images/caseStudy/bcof-1.png"
import mob_logo_o from "../images/caseStudy/mob_logo-1.png"











import {Helmet} from "react-helmet"
export default class Resources extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
        <Layout>
          <Helmet>
            <title>CueForGood Case Studies - A Collection of Some of our Work </title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta property="fb:admins" content="1046558399"/>
            <link rel="canonical" href={this.state.url}/>
            <meta name="description"
                  content="We constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. Take an in-depth look into how we sailed through a few of our projects"/>
            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title"
                  content="CueForGood Case Studies - A Collection of Some of our Work"/>
            <meta property="og:description"
                  content="We constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. Take an in-depth look into how we sailed through a few of our projects"/>
            <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="We constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. Take an in-depth look into how we sailed through a few of our projects"/>
            <meta name="twitter:title"
                  content="CueForGood Case Studies - A Collection of Some of our Work"/>
            <meta name="twitter:site" content="@CueForGood"/>
            <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg"/>
          </Helmet>
          <section className="our-workn">
              <div className="container">
              <h2 className="heading_main">Our Work</h2>
              <p>A few good brands and causes who have engaged CueForGood:</p>
              <ul className="ourW-clientlogo">
                <li><img src={flare} alt="flare" /></li>
                <li><img src={herology} alt="herology" /></li>
                <li><img src={bab} alt="bab" /></li>
                <li><img src={buk } alt="buk" /></li>
                <li><img src={goat} alt="goat" /></li>
                <li><img src={cad } alt="cad" /></li>
                <li><img src={IG} alt="IG" /></li>
                <li><img src={rw } alt="Rapids wholesale" /></li>
                <li><img src={dakine } alt="dakine" /></li>
                <li><img src={myF } alt="my Fruits" /></li>
                <li><img src={pj } alt="Planet Jill" /></li>
                <li><img src={ apex} alt="apex" /></li>
                <li><img src={kiwi } alt="kiwi" /></li>
                <li><img src={ jiva} alt="jiva" /></li>
                <li><img src={clam } alt="clam" /></li>
                <li><img src={ww } alt="ww" /></li>
                <li><img src={paradise } alt="paradise" /></li>
                <li><img src={PD } alt="PD" /></li>
                <li><img src={atg } alt="atg" /></li>
                <li><img src={sch } alt="Join Switch" /></li>
                <li><img src={nirvana } alt="nirvana" /></li>
                <li><img src={thtr } alt="thtr" /></li>
                <li><img src={ LP} alt="LP" /></li>
                <li><img src={PC } alt="PC" /></li>
                <li><img src={dp } alt="diamonds pro" /></li>
                <li><img src={pm } alt="peter manning" /></li>
                <li><img src={ryda } alt="Ryda" /></li>
                <li><img src={blc } alt="bellane coffee" /></li>
                <li><img src={dd } alt="diamond designs" /></li>
                <li><img src={az } alt="alzheimer speaks" /></li>
                <li><img src={bc } alt="Blinc" /></li>



              </ul>
              <div className="mob_logo">
                <img src={mob_logo_o } alt="client logo in mobile" />
              </div>
              </div>
          </section>
          <section className=" resourace-casestudy">
            <div className="container">
            <h1 className="heading_main">Case Studies</h1>
              <div className="ser_sec_row_lt pd_right_40">
                <div className="ser_sec_row_lt_text">
                  <p>
                    Meticulously honing our skills over the last 15 years, we constantly strive to deliver on our
                    promise
                    of in-depth client collaborations fueled by clear & consistent communication.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt pd_left_40">
                <div className="ser_sec_row_lt_text">
                  <p>
                    Adhering to timing & budget expectations of our clients, we build & modify the solutions their
                    customers are looking for, thereby reinforcing the brand identity in the industry. Take an in-depth
                    look into how we sailed through a few of our projects.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={JIVA_AG} alt="All that grows seeds " className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">JIVA</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}><strong>Organic Search</strong></span>
                <h4>
                CueForGood Transforming Blogging Results: An Astounding 354.27% Organic Traffic Surge in Just 6 Months
                </h4>

                <Link to="/case-study/jiva-organic-content-marketing-growth" className="btn main_cta focus-visible" aria-label="read more abou JIVA" id="first-focus-element">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
           <section className=" resources-petdoors fleex right-img ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={DR_B} alt="Dr. B Dental Solutions – A collection of dental care products" className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">Dr. B Dental Solutions</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}><strong>Paid Ads</strong></span>
                <h4>
                Dr. B Dental Solutions Achieves 215% Revenue Growth with Google Shopping Ads 
                </h4>

                <Link to="/case-study/dental-ecommerce-google-shopping-ads" className="btn main_cta focus-visible" aria-label="read more about Dr B">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
          <section className=" resources-petdoors fleex ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={ATG_Seeds} alt="All that grows seeds " className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">AllThatGrows</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}><strong>Paid Ads</strong></span>
                <h4>
                AllThatGrows Achieves 2.97x ROAS & 500+ Conversions with Google Ads.
                </h4>

                <Link to="/case-study/seed-ecommerce-on-shopify-google-ads" className="btn main_cta focus-visible" aria-label="read more about ecommerce ads">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
          <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={ddu_resources}
                      alt="Womanswork"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_left_40 ">
                  <h2 className="heading_main">Diamond Designs</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}><strong>FACEBOOK/ INSTAGRAM</strong></span><span className="highlight-span" style={{marginRight: '10px'}}><strong>PAID ADS</strong></span>
                  <h4>
                  Achieving a ROAS of 5x with Facebook/ Instagram ads within just 5 months.
                  </h4>

                  <Link to="/case-study/diamond-designs/" className="btn main_cta focus-visible" aria-label="read more about Diamond Design">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <img
                      src={Atg_website}
                      alt="Atg Website"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">AllThatGrows</h2>
                  <span className="highlight-span"><strong>Shopify</strong></span> <span className="highlight-span"><strong>Creative Design</strong></span> <span className="highlight-span"><strong>Digital Marketing</strong></span>
                  <h4>
                    Building brand identity, packaging designs & marketing campaigns for AllThatGrows.
                  </h4>

                <Link to="/case-study/allthatgrows/" className="btn main_cta focus-visible" aria-label="read more about AllThatGrows">
                    Read more 
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex right-img">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_rt pd_right_40">
                <img
                  src={Perennial_seo}
                  alt="Perennial Seo"
                  className="img-responsive"
                />
              </div>
              <div className="ser_sec_row_lt pd_left_40">
                <h2 className="heading_main">Perennial Cycle</h2>
                <span className="highlight-span"><strong>SEO</strong></span>
                <h4>
                  How PerennialCycle increased YOY Organic Revenue by 155.63% with just Elemental SEO.
                  </h4>

                <Link to="/case-study/seo-perennial-cycle"
                  className="btn main_cta focus-visible"
                  aria-label="Read more about SEO for Perennial Cycle">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className=" resources-petdoors fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img src={Seed_Planet} alt="Seed Planet" className="img-responsive" />
                
                </div>
                <div className="ser_sec_row_rt pd_right_40 ">
                  <h2 className="heading_main">AllThatGrows</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}><strong>Facebook/ Instagram</strong></span><span className="highlight-span" style={{marginRight: '10px'}}><strong>Paid Ads</strong></span>
                  <h4>
                    Optimizing Facebook/ Instagram paid ads to achieve a 3.27x ROAS, consistently over the years.
                  </h4>

                  <Link to="/case-study/high-roas-during-pandemic" className="btn main_cta focus-visible" aria-label="read more about ATG(high roas during pandemic)">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <img src={Petdog__rs} alt="PetDoors" className="img-responsive"/>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">PetDoors</h2>
                  <span className="highlight-span"><strong>magento</strong></span> <span className="highlight-span"><strong>SEO</strong></span>
                  <h4>
                    Merging revenue-driven blogs to increase organic sessions by 106.94% for PetDoors.
                  </h4>

                <Link to="/case-study/merging-blog-pages-seo/" className="btn main_cta focus-visible" aria-label="read more about Petdoors">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_left_40">
                <img
                  src={Betty_img}
                  alt="Perennial cycle"
                  className="img-responsive"
                />
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">Betty and Biddy</h2>
                <span className="highlight-span"><strong>Google Shopping Engine</strong></span>
                <h4>
                  Raising the revenue by 1699% for Betty and Biddy with Google Shopping Campaigns
                  </h4>

                <Link to="/case-study/betty-and-biddy-google-shopping" className="btn main_cta focus-visible" aria-label="read more about BAB ">
                  Read more
                  </Link>
              </div>
            </div>
          </div>
        </section>

          <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={Dipro_img}
                      alt="Womanswork"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_left_40 ">
                  <h2 className="heading_main">The Diamond Pro</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}><strong>SEO</strong></span>
                  <h4>
                  Increasing Affiliate Clicks by 169% with SEO for Diamond Industry Client.
                  </h4>

                  <Link to="/case-study/seo-affiliate-marketing-diamond-industry/" className="btn main_cta focus-visible" aria-label="the diamonds pro - read more">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img
                  src={Perennial_img}
                  alt="Perennial cycle"
                  className="img-responsive"
                />
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">Perennial Cycle</h2>
                <span className="highlight-span"><strong>Google Shopping Engine</strong></span>
                <h4>
                 How Google Shopping Engine Played A Big Role In Increasing The Revenue Meter By 616.91%.
                  </h4>

                <Link to="/case-study/google-shopping-perennial-cycle"
                  className="btn main_cta focus-visible"
                  aria-label="Read more about Google Shopping for Perennial Cycle">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </section>
          <section className=" resources-petdoors fleex right-img">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={igadget__rs} alt="Ansel and Ivy" className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">iGadget</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}><strong>Google Shopping Engine</strong></span>
                <h4>
                Achieving 23.59% of Total Orders through Google Shopping
                </h4>

                <Link to="/case-study/mobile-repairs-accessory-store-igadget/" className="btn main_cta focus-visible" aria-label="read more about iGadget">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
          <section className=" resources-petdoors fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={Amazon_case}
                      alt="All That Grows"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_right_40 ">
                  <h2 className="heading_main">AllThatGrows</h2>
                  <span className="highlight-span"><strong>Marketplace Optimization</strong></span> 
                  <h4>
                    Leveraging the Amazon Marketplace to increase revenue for AllThatGrows.
                  </h4>

                <Link to="/case-study/amazon-marketplace/" className="btn main_cta focus-visible" aria-label="read more about All That Grows">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={WomenWork__rs}
                      alt="Womanswork"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_right_40 ">
                  <h2 className="heading_main">Womanswork</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}><strong>Magento</strong></span><span className="highlight-span" style={{marginRight: '10px'}}><strong>Speed Optimization</strong></span>
                  <h4>
                    Ensuring speed optimization for Womanswork's Magento 2 eStore.
                  </h4>

                  <Link to="/case-study/site-speed-ww/" className="btn main_cta focus-visible" aria-label="read more about Womans Work">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
            <section className=" resources-petdoors fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_right_40">
                  <img src={Ansel_Brand} alt="Ansel and Ivy" className="img-responsive" />
                
                </div>
                <div className="ser_sec_row_rt pd_left_40 ">
                  <h2 className="heading_main">Ansel & Ivy </h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}><strong>Google Shopping Engine</strong></span>
                  <h4>
                   How CueForGood helped Ansel & Ivy generate 12.35% of its total revenue from Google Shopping.
                  </h4>

                  <Link to="/case-study/google-shopping-ansel-and-ivy" className="btn main_cta focus-visible" aria-label="read more about Ansel and Ivy">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>    
          <section className="home-resourse__row home-resourse__row_res">
            <div className="container">
              <h2 className="heading_main">Resources</h2>
              <div className="resourse__lt pd_right_40">
                <p>
                  Be sure to check off all the points on this specially-curated Conversion Rate Optimisation (CRO)
                  before
                  investing in your next big eCommerce venture.
                </p>
                <h3>91 Point CRO Checklist & Infographic</h3>
              <Link to="/holygrail-of-ecommerce-conversion-rate-optimization-91-point-checklist/"
                className="main_cta focus-visible"
                aria-label="Download 91-Point Checklist for eCommerce Conversion Rate Optimization">
                Download
              </Link>
              </div>
              <div className="resourse__rt pd_left_40">
                <p>
                  Follow our 40-Point Checklist Guide for quick tips & feasible steps to smoothly switch the HTTP
                  version
                  of your website to a more secure version, HTTPS.
                </p>
                <h3>40 Point Checklist for a Successful HTTP to HTTPS Migration </h3>
              <Link to="/40-point-checklist-for-a-successful-http-to-https-migration/"
                className="main_cta focus-visible"
                aria-label="Download 40-Point Checklist for HTTP to HTTPS Migration">
                Download
              </Link>
              </div>
            </div>
          </section>

          <Clutch/>
        </Layout>
    )
  }
}
                  